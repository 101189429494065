
import ProductBrand from '../product/components/ProductBrand'

export default {
    name: 'Empty',
    layout: 'main-head',
    components: {
        ProductBrand
    },
    asyncData({api, cookies, ...context}) {
        context.res?context.res.statusCode = 404:''
    },
    props: {},
    data() {
        return {
            recommend: {          // 推荐商品
                loading: false,
                visible: false,   // 是否显示
                hasPull: false,   // 是否上拉加载
                list: [],
                showPosition: -1,
                pageNum: 1,
                pageSize: 10,
                totalPage: 1,
            },
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getRecommendAjax()
    },
    methods: {
        getRecommendAjax() { // 商品推荐
            this.$api.common.getRecCommon({
                pageNum: this.recommend.pageNum,
                pageSize: this.recommend.pageSize,
                showPosition: this.recommend.showPosition
            }).then(response => {
                const res = response.result || {};
                if(res.list){
                    this.recommend.list = this.recommend.list.concat(res.list || []) || []
                    this.recommend.visible = this.recommend.list.length
                    this.recommend.totalPage = res.totalPage
                }else{
                    this.recommend.list = this.recommend.list.concat(res || []) || []
                    this.recommend.visible = this.recommend.list.length
                    this.recommend.totalPage = 1
                }
            })
        },
        goIndex() {
            this.$router.push({
                name: 'Home'
            })
        },
        emitBottomLoading(e, callBack) {
            const hasNext = this.recommend.totalPage > this.recommend.pageNum;
            if(e.isIntersecting && hasNext) {
                this.recommend.pageNum += 1
                this.getRecommendAjax()
            }
            callBack(!hasNext)
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('PAGE NOT FOUND'),
                keywords: this.$translate('PAGE NOT FOUND'),
                description: this.$translate('PAGE NOT FOUND'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
    },
}
