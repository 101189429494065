
export default {
    name: 'forbidden',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {}
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('System Updating'),
                keywords: this.$translate('System Updating'),
                description: this.$translate('System Updating'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
    },
}
